<template>
    <div class="content">
		<!-- 数据面板 -->
        <div class="row f-acjsb">
			<template v-if="board">
				<div class="section">
					<!-- 金额 -->
					<div class="top f-acjsb">
						<div class="txt">金额</div>
						<div class="tips">
						  <a-tooltip placement="top">
							<template slot="title">
							  <span>今日数据</span>
							</template>
							<a-icon type="exclamation-circle" />
						  </a-tooltip>
						</div>
					</div>
					<div class="price">
						￥{{board.amount.today}}
					</div>
					<div class="range">
						<div class="up">
							<span class="txt">比昨日上涨 {{board.amount.yesterday}}%</span>
							<a-icon type="caret-up"  style="color: #52C41A;"/>
						</div>
						<div class="down">
							<span class="txt">比上周下降 {{board.amount.week}}%</span>
							<a-icon type="caret-down"  style="color:#FF4D4F;"/>
						</div>
					</div>
					<div class="bottom">
						总金额 ￥{{board.amount.total}}
					</div>
				</div>
				<div class="section">
					<!-- 访客 -->
					<div class="top f-acjsb">
						<div class="txt">访客</div>
						<div class="tips">
							<a-tooltip placement="top">
								<template slot="title">
								  <span>访客</span>
								</template>
								<a-icon type="exclamation-circle" />
							</a-tooltip>
						</div>
					</div>
					<div class="price">
						{{board.visitor.total}}
					</div>
					<div class="range">
						<div id="visitor"></div>
					</div>
					<div class="bottom">
						今日访客 {{board.visitor.today}}
					</div>
				</div>
				<div class="section">
					<!-- 下单人数 -->
					<div class="top f-acjsb">
						<div class="txt">下单人数</div>
						<div class="tips">
							<a-tooltip placement="top">
								<template slot="title">
								  <span>下单人数</span>
								</template>
								<a-icon type="exclamation-circle" />
							</a-tooltip>
						</div>
					</div>
					<div class="price">
						￥{{board.place_order.today}}
					</div>
					<div id="people"></div>
					<div class="bottom">
						总金额 ￥{{board.place_order.total}}
					</div>
				</div>
				<div class="section">
					<!-- 订单信息 -->
					<div class="top f-acjsb">
						<div class="txt">订单信息</div>
						<div class="tips">
							<a-tooltip placement="top">
								<template slot="title">
								  <span>订单信息</span>
								</template>
								<a-icon type="exclamation-circle" />
							</a-tooltip>
						</div>
					</div>
					<div class="price">
						￥{{board.order_volume.today}}
					</div>
					<div class="range">
						<div class="up">
							<span class="txt">比昨日上涨 {{board.order_volume.yesterday}}%</span>
							<a-icon type="caret-up"  style="color: #52C41A;"/>
						</div>
						<div class="down">
							<span class="txt">比上周下降 {{board.order_volume.week}}%</span>
							<a-icon type="caret-down"  style="color:#FF4D4F;"/>
						</div>
					</div>
					<div class="bottom">
						总金额 ￥{{board.order_volume.total}}
					</div>
				</div>
			</template>
		</div>
		
		
		<!-- 数据表 -->
		<div class="rows mt-30">
			<div class="f-acjsb">
				<div class="left">
					<a-menu v-model="current" mode="horizontal" @click="changemenu">
						<a-menu-item key="amount">金额</a-menu-item>
						<a-menu-item key="visitor">访客</a-menu-item>
						<a-menu-item key="people">下单人数</a-menu-item>
						<a-menu-item key="volume">订单信息</a-menu-item>
					</a-menu>
				</div>
				<div class="right f-acjsb">
					 <div class="txtitem  txtitem-active">
						 今天
					 </div>
					 <div class="txtitem">
						 本周
					 </div>
					 <div class="txtitem">
						 本月
					 </div>
					 <div class="txtitem">
						 全部
					 </div>
					 <div class="txtitem">
						<a-range-picker >
						  <a-icon slot="suffixIcon" type="calendar" />
						</a-range-picker>
					 </div>
				</div>
			</div>
			
			<div class=" rowsdata f-acjsb">
				<div class="lchart">
					<div id="container"></div>
				</div>
				
				<div class="rshop">
					<p>店铺排名</p>
					<div class="shoplist">
						<template v-for="(item,index) in rank">
							<div class="shopitem f-acjsb">
								<div class="name">
									<a-badge :count="item.ranking":number-style="{backgroundColor: '#000000',color: '#ffffff'}"/>
									<span style="padding-left: 15px;">{{item.name}}</span>
								</div>
								<div class="shopdata">
									{{item.number}}
								</div>
							</div>
						</template>
					</div>
					<!-- <div class="enter f-ajc">
						<a-button type="primary">
						  进入店铺
						</a-button>
					</div> -->
				</div>
			</div>
			
		</div>
		
		<!-- 门店注册 -->
		
    </div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex";
	import { Chart } from '@antv/g2';
	
    export default {
		data() {
			return {
				current: ['amount'],
				chartdata:[
					{title:"2021-04-19",number:300},
					{title:"2021-04-20",number:3030},
					{title:"2021-04-21",number:3020}
				],
				rank:[],
				chart:"",
				board:"",
				charttop:"",
				
				// 门店信息
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
			}
		},
		created() {
			this.gethome()
		},
        mounted () {

			// 初始化首页数据
			// this.gethome()
        },
        methods: {
			...mapActions({
				getdata:"home/getdata",
				getindex:"home/getindex"
			}),
			async gethome(){
				let data = await this.getindex()
				this.board = data
				console.log(data,"上面数据");
				// 先画图在更新数据
				this.initchart()
				await this.gethomedata(this.current[0])
				// 访客
				await this.viewtop("visitor")
				// 下单人数
				await this.viewtop("people")
				
				
			},
			// 点击金额，访客，下单，订单
			changemenu(e){
				// 根据e.key来判断渲染的当前数据
				this.gethomedata(e.key)
			},
			/* 
			 *xname 异步获取数据方法
			 *xname
			 */
			// 获取数据方法
			async gethomedata(xname){
				let data = await this.getdata(xname)
				// 赋值数据
				this.chartdata = data.data
				this.rank = data.ranking
				this.chart.changeData(this.chartdata);
			},
			// 上面数据图表方法
			async viewtop(xname){
				
			   let data = await this.getdata(xname)
				
				this.$nextTick(()=>{
					const chart = new Chart({
					  container: xname,
					  autoFit: true,
					  setOption:true,
					  height: 42,
					});
					if(xname == "visitor"){
						chart.data(data.data);
					}else{
						chart.data(data.data);
					}
					chart.axis(false);
					chart.tooltip({
						 position: 'right',
						  crosshairs: {
						    type: 'y',
						  },
					})
					chart.interaction('active-region');
					if(xname == "visitor"){
						chart.area().position('title*number');
					}else{
						chart.interval().position('title*number');
					}
					
					chart.render();
				})
			},
			// 画图作业
			initchart(){
				this.$nextTick(()=>{
					this.chart = new Chart({
					  container: 'container',
					  autoFit: true,
					  setOption:true,
					   height: 500,
					   width:1200
					});
					this.chart.data(this.chartdata);
					this.chart.scale('number', {
					  nice: true,
					});
					
					this.chart.tooltip({
					  showMarkers: false
					});
					this.chart.interaction('active-region');
					
					this.chart.interval().position('title*number');
					
					this.chart.render();
				})				
			}
        },
    }
</script>

<style lang="less" scoped>
	// bg
	.content{
		margin: -24px;
		background:@bg-color;
	}
	.row{
		background: @bg-color;
	}
	.rows{
		background: #fff;
		.right{
			
			.txtitem{
				padding-right: 24px;
			}
			.txtitem-active{
				color: @primary-color;
			}
		}

		.rowsdata{
			padding: 24px;
			.lchart{
				width: 60%;
				
			}
			.rshop{
				width: 412px;
				height: 500px;
				padding-left: 30px;
				position: relative;
				.enter{
					width: 100%;
					text-align: center;
					position: absolute;
					bottom: 0;
				}
			}
		}
	}
	// 顶部版块
	.section{
		background: #fff;
		padding: 24px;
		width: 400px;
		.top{
			.txt{
				color: #a5a6a9;
			}
		}
		.price{
			font-size: 38px;
			font-weight: bold;
		}
		.range{
			.txt{
				padding-right: 10px;
			}
		}
		.bottom{
			padding-top: 15px;
			border-top: 1px solid @bg-color;;
		}
	}
	
</style>